import React, { useState } from "react";
import {
  makeStyles,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  Grid,
} from "@material-ui/core";
import { CheckCircleOutline } from "@mui/icons-material";
import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";
import { URLGenerator } from "../../../utils/URLGenerator";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    borderRadius: "100px",
    boxShadow: "0px 4px 30px 0px rgba(0, 0, 0, 0.25)",
    width: "fit-content",
    padding: 10,
    background: theme.palette.common.white,
    margin: "0 auto",
    marginBottom: theme.spacing(4),
    justifyContent: "center",
    display: "flex",
    gap: 10,
  },
  button: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 3),
    textTransform: "none",
  },
  selected: {
    backgroundColor: "#201D4D",
    borderRadius: "100px",
    padding: theme.spacing(1, 6),
    color: "#fff",
    "&:hover": {
      backgroundColor: "#201D4D",
    },
  },
  unselected: {
    backgroundColor: "#fff",
    borderRadius: "100px",
    color: "#201D4D",
    padding: theme.spacing(1, 6),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  planCard: {
    borderRadius: 15,
    boxShadow: "0px 0px 14px 5px rgba(0, 0, 0, 0.13)",
    padding: theme.spacing(2),
    width: "24%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  planTitle: {
    color: "#201D4D",
    fontWeight: 800,
    padding: theme.spacing(2),
    paddingBottom: 0,
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontSize: 24.5,
  },
  price: {
    fontWeight: "bold",
    color: "#39ADC4",
    fontFamily: "Nunito",
    fontSize: 30,
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  offerButton: {
    marginTop: theme.spacing(2),
    color: "#fff",
    backgroundColor: "#39adc4",
    "&:hover": {
      backgroundColor: "#33a0c4",
    },
    margin: "0 auto",
    justifyContent: "center",
    display: "flex",
    borderRadius: 50,
    textTransform: "none",
    width: "100%",
    cursor: "auto",
    fontFamily: "Nunito",
    fontSize: 18,
    fontWeight: 500,
  },
  testButton: {
    marginTop: theme.spacing(2),
    color: "#fff",
    background:
      "linear-gradient(90deg, #39ADC4 0%, #9839C4 100%, #B443CB 100%)",
    "&:hover": {
      opacity: 0.8,
    },
    margin: "0 auto",
    justifyContent: "center",
    display: "flex",
    textTransform: "none",
    padding: theme.spacing(1, 5),
    borderRadius: "10px",
    fontSize: "17px",
  },
  appInfosTitle: {
    fontSize: 45,
    textAlign: "center",
    color: "#39adc4",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    display: "table",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      fontSize: 35,
    },
  },
  purple: {
    color: "#201D4D",
    marginBottom: theme.spacing(5),
  },
  rowsGrid: {
    justifyContent: "space-around",
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(8, 0),
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      gap: 35,
    },
  },
  itemList: {
    fontSize: 18,
    color: "#7B7F87",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 500,
  },
  pricePeriod: {
    fontWeight: 400,
    fontSize: "0.8em",
  },
}));

const PlanCard = ({ planType, monthlyPrice, isAnnual, features }) => {
  const classes = useStyles();
  const downloadURL = URLGenerator();
  const annualPrice = (monthlyPrice * 12 * 0.85).toFixed(2);

  const highlightKeywords = (text) => {
    const keywords = [
      "SMS",
      "E-mail",
      "Push",
      "Whatsapp",
      "2 profissionais",
      "50 profissionais",
    ];
    const parts = text.split(new RegExp(`(${keywords.join("|")})`, "gi"));
    return parts.map((part, index) =>
      keywords.includes(part) ? <b key={index}>{part}</b> : part
    );
  };

  return (
    <Card className={classes.planCard}>
      <Typography variant="h3" component="div" className={classes.planTitle}>
        {planType.toUpperCase()}
      </Typography>
      <CardContent>
        <Typography variant="h5" component="div" className={classes.price}>
          <span className={classes.pricePeriod}>R$</span>
          <span className={classes.priceValue}>
            {isAnnual ? annualPrice : monthlyPrice}
          </span>
          <span className={classes.pricePeriod}>
            /{isAnnual ? "ano" : "mês"}
          </span>
        </Typography>
        <Button variant="contained" className={classes.offerButton}>
          Apenas R$
          {isAnnual
            ? (annualPrice / 252).toFixed(2)
            : (monthlyPrice / 30).toFixed(2)}{" "}
          por dia!
        </Button>
        <List>
          {features.map((feature, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleOutline
                  sx={{ color: "#39ADC4", fontSize: "30px" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={highlightKeywords(feature)}
                className={classes.itemList}
              />
            </ListItem>
          ))}
        </List>
        <Button
          className={classes.testButton}
          onClick={() => {
            mixpanel.track("site - download pelo site | btn - card de preço");
            ReactPixel.track("site - download pelo site | btn - card de preço");
          }}
          href={downloadURL || "https://prit-business.onelink.me/Mxb0"}
        >
          Teste agora
        </Button>
      </CardContent>
    </Card>
  );
};

const PricingSection = () => {
  const classes = useStyles();
  const [isAnnual, setIsAnnual] = useState(false);
  const features = {
    basico: [
      "2 profissionais cadastrados",
      "Lembrete de confirmação para o cliente via E-mail",
      "Notificação de nova reserva via Push",
      "Agendamentos ilimitados",
      "Controle financeiro",
      "Cadastro de clientes",
      "Cobrança antecipada (opcional) com tarifas padrão por Pix e Cartão de crédito",
      "Acompanhamento exclusivo e personalizado",
    ],
    avancado: [
      "50 profissionais cadastrados",
      "Lembrete de confirmação para o cliente via SMS",
      "Notificação de nova reserva via Whatsapp",
      "Agendamentos ilimitados",
      "Controle financeiro",
      "Cadastro de clientes",
      "Cobrança antecipada (opcional) com tarifas padrão por Pix e Cartão de crédito",
      "Acompanhamento exclusivo e personalizado",
    ],
    profissional: [
      "50 profissionais cadastrados",
      "Lembrete de confirmação para o cliente via Whatsapp",
      "Notificação de nova reserva via Whatsapp",
      "Agendamentos ilimitados",
      "Controle financeiro",
      "Cadastro de clientes",
      "Cobrança antecipada (opcional) com tarifas padrão por Pix e Cartão de crédito",
      "Acompanhamento exclusivo e personalizado",
    ],
  };

  return (
    <div id="pricing">
      <Typography
        variant="h2"
        className={classes.appInfosTitle + " " + classes.purple}
      >
        Escolha o plano ideal para você!
      </Typography>
      <ButtonGroup className={classes.buttonGroup} exclusive orientation="none">
        <Button
          className={isAnnual ? classes.unselected : classes.selected}
          onClick={() => setIsAnnual(false)}
        >
          Mensal
        </Button>
        <Button
          className={isAnnual ? classes.selected : classes.unselected}
          onClick={() => setIsAnnual(true)}
        >
          Anual
        </Button>
      </ButtonGroup>
      <Grid className={classes.rowsGrid}>
        <PlanCard
          planType="Básico"
          monthlyPrice={29}
          isAnnual={isAnnual}
          features={features.basico}
        />
        <PlanCard
          planType="Avançado"
          monthlyPrice={49}
          isAnnual={isAnnual}
          features={features.avancado}
        />
        <PlanCard
          planType="Profissional"
          monthlyPrice={99}
          isAnnual={isAnnual}
          features={features.profissional}
        />
      </Grid>
    </div>
  );
};

export default PricingSection;
