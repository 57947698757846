import axios from "axios";
import { signInAnonymously } from "firebase/auth";
import { Firebase } from "./firebase";

const api = axios.create({
	baseURL: "https://api.prit.app/",
	headers: {
		"content-type": "application/json",
	},
});

api.interceptors.request.use(
	async (config) => {
		let token = localStorage.getItem("token");
		const res = await signInAnonymously(Firebase);
		token = await res.user.getIdToken();

		localStorage.setItem("token", token);
		config.headers.Authorization = `Bearer ${token}`;
		
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default api;
