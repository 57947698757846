
import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Avatar, makeStyles, CircularProgress,  } from "@material-ui/core";
import { Pagination, Stack } from '@mui/material';
import businessServices from "../../services/businessServices";
import PageHeader from "../../components/common/pageHeader";
import BlueButton from "../../components/common/blueButton";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  homePage: {
    width: "100%",
    height: "100vh",
    padding: 0,
  },
  spacingBox: {
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  division: {
    width: "100%",
    background: "#7B7F87",
  },
  appIntro: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 20,
    padding: theme.spacing(5, 5),
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(2, 4),
    },
  },
  professionalBox:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 5),
    borderRadius: 10,
    boxShadow: '0px 1px 23px 0px rgba(0, 0, 0, 0.17)',
    height: '100px',

  },
  professionalBoxInfos: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 30,
  },
  avatar: {
    width: 80,
    height: 80,
  },
  professionalBoxInfosText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    gap: 10,
  },
  pagination: { justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom: 15, position: 'fixed', bottom: 0, left: 0, right: 0, },
}));

function formatAddress(address) {
  const { street, number, unit } = address;
  const formattedAddress = `${street || ""}, ${unit || ""} ${number || ""}`;
  return formattedAddress;
}

const ProfessionalBox = ({ name, thumbnailUrl, id, address }) => {
  const classes = useStyles();
  const formattedAddress = address ? formatAddress(address) : "Endereço não disponível";
  return (
    <Grid className={classes.professionalBox}       data-aos={"zoom-in"}
    data-aos-duration="800">
      <Grid className={classes.professionalBoxInfos}>
        <Avatar src={thumbnailUrl} className={classes.avatar}></Avatar>
        <Grid className={classes.professionalBoxInfosText}>
          <Typography>{name || "Nome do estabelecimento"}</Typography>
          <Typography>{formattedAddress}</Typography>
        </Grid>
      </Grid>
      <BlueButton url={"https://web.pritweb.app/business/" + id} text="Agendar horário" noEffect></BlueButton>
    </Grid>
  );
};


function ProfessionalPage() {
  const classes = useStyles();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [professionals, setProfessionals] = useState([]);
  const perPage = 10;

  
  useEffect(() => {
    const fetchProfessionals = async () => {
      try {
        const response = await businessServices.getAllBusinessPerCategories(
          currentPage,
          perPage,
          id
        );
    
        const data = response.data;
        setProfessionals(data);
    
        if(response.headers['x-total-count'] % perPage === 0){
          setTotalPages(response.headers['x-total-count'] / perPage);
        } else {
          setTotalPages(Math.trunc(response.headers['x-total-count'] / perPage) + 1);
        }
  
        window.scrollTo(0, 0);
        setLoading(false);
      } catch (error) {
        console.error("Falha ao buscar profissionais:", error);
      }
    };
    fetchProfessionals();
  }, [currentPage]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Container maxWidth={false} className={classes.homePage}>
      <PageHeader is={true}></PageHeader>
      {loading ? (
        <CircularProgress
          color="#39adc4"
          style={{ left: "50%", top: "50%", position: "absolute" }}
        />
      ) : (
        <>
          <Grid className={classes.appIntro}>
            {professionals.map((professional) => (
              <ProfessionalBox key={professional.id} {...professional} />
            ))}
          </Grid>
          <Stack spacing={2} className={classes.pagination}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handleChange}
            />
          </Stack>
        </>
      )}
    </Container>
  );
}

export default ProfessionalPage;
