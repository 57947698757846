import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import ReactPixel from "react-facebook-pixel";
import Zendesk, { ZendeskAPI } from './utils/ZendeskConfig';
import Home from './pages/Home';
import './App.css';
import ProfessionalsExplorer from './pages/ProfessionalsExplorer';
import ProfessionalPage from './pages/ProfessionalPage';
import { appendGTMHeadScript } from './utils/GTM';

const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_ID;

function App() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
  ReactPixel.pageView();
  appendGTMHeadScript();

  const handleLoaded = () => {
    ZendeskAPI('messenger', 'close');
  };

  return (
    <>
     <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KW7TL5F"
                height="0" width="0" style={{display: 'none', visibility: 'hidden'}}></iframe>
      </noscript>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profissionais" element={<ProfessionalsExplorer/>} />
          <Route path="/profissionais/buscar" element={<ProfessionalPage />} />
        </Routes>
      </BrowserRouter>
      <Zendesk defer zendeskKey={ZENDESK_KEY} onLoaded={handleLoaded} />
    </>
  );
}

export default App;