import React from 'react';
import Button from '@material-ui/core/Button';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import mixpanel from 'mixpanel-browser';
import ReactPixel from 'react-facebook-pixel';
import { URLGenerator } from '../../../utils/URLGenerator';

const useStyles = makeStyles({
  root: {
    borderRadius: 10,
    background: "linear-gradient(0deg, #39ADC4 0%, #39ADC4 100%)",
    border: "none",
    color: "#FFF",
    fontSize: 20,
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    padding: "8px 25px",
    width: "fit-content",
    minWidth: "162px",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      background: "#fff",
      outline: "1px solid #39ADC4",
      color: "#39ADC4",
    },
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    whiteSpace: "nowrap",
  },
});

function BlueButton(props) {
  const classes = useStyles();
  const { text, url, delay } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const downloadURL = URLGenerator();

  return (
    <Button
      className={classes.root}
      onClick={() => {
        ReactPixel.track(`site - download pelo site | btn - ${text}`); 
        mixpanel.track(`site - download pelo site | btn - ${text}`, {
          my_custom_prop: "foo",
        });
      }}
      href={url || downloadURL || "https://prit-business.onelink.me/Mxb0"}
      data-aos={isMobile ? "" : "zoom-in"}
      data-aos-duration="800"
      data-aos-delay={!delay ? "" : delay}
      data-aos-offset={10}
    >
      {text}
    </Button>
  );
}

export default React.memo(BlueButton);
