import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"

const devConfig = {
	apiKey: "AIzaSyALuw6dyIzNThmrj2P93TpR9lnD_ou-6Xg",
	authDomain: "prit-app.firebaseapp.com",
	databaseURL: "https://prit-app.firebaseio.com/",
	projectId: "prit-app",
	storageBucket: "prit-app.appspot.com",
	messagingSenderId: "964307259443",
	appId: "1:964307259443:web:188ab6ac14a95a10b55346",
	measurementId: "G-LMJDZPWNJB",
};
const config = devConfig;

const app = initializeApp(config);

export const Firebase = getAuth(app);
