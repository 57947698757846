import React from "react";
import { Box, Typography, Grid, makeStyles } from "@material-ui/core";
import WhatsAppIcon from "../../../assets/icons/support-whatsapp.png";
import CustomSupportIcon from "../../../assets/icons/support-attendant.png";
import NonBusinessHoursIcon from "../../../assets/icons/support-clock.png";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    textAlign: "center",
    alignItems: 'center',
    marginBottom:  theme.spacing(14),
    [theme.breakpoints.down('md')]: {
      marginBottom:  theme.spacing(10),
    },
  },
  title: {
    color: "#39adc4",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 40,
    marginBottom:  theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
      padding: '0px 10px'
    },
  },
  iconTextContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  icon: {
    maxWidth: "100%",
    height: "auto",
    width: "auto",
  },
  description: {
    marginTop: theme.spacing(1),
    color: "#6c757d",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 18,
    width: "45%",
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
  },
  boxesWrapper: {
    display: 'flex',
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
    marginLeft: theme.spacing(18),
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0),
    },
  }
}));

const SupportSection = () => {
  const classes = useStyles();

  return (
    <Box className={classes.sectionContainer}>
      <Typography variant="h2" className={classes.title}>
        Juntos com você em toda a sua jornada!
      </Typography>
      <Grid container className={classes.boxesWrapper}>
        <Grid item className={classes.iconTextContainer}>
          <img
            src={WhatsAppIcon}
            alt="Atendimento via WhatsApp"
            className={classes.icon}
          />
          <Typography variant="h3" className={classes.description}>
            Atendimento via WhatsApp
          </Typography>
        </Grid>
        <Grid item className={classes.iconTextContainer}>
          <img
            src={CustomSupportIcon}
            alt="Suporte personalizado"
            className={classes.icon}
          />
          <Typography variant="h3" className={classes.description}>
            Suporte personalizado desde o momento que você baixa o app
          </Typography>
        </Grid>
        <Grid item className={classes.iconTextContainer}>
          <img
            src={NonBusinessHoursIcon}
            alt="Equipe disponível"
            className={classes.icon}
          />
          <Typography variant="h3" className={classes.description}>
            Equipe disponível mesmo fora do horário comercial
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SupportSection;
