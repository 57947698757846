import api from "./api";

const CONTROLLER_NAME = "/business";
const CONTROLLER_NAME_SECTOR = "/sector";

export const getSectorsList = () => {
	return api.get(CONTROLLER_NAME_SECTOR, { params: { without_category: "true" } });
};

export const getAllBusinessPerCategories = (page, perPage, categories) => {
	return api.get(`${CONTROLLER_NAME}`,{ params: { page: `${page}`, per_page: `${perPage}`, sector_id: categories, status: 'active'} });
};

const businessServices = {
	getSectorsList,
	getAllBusinessPerCategories,
};

export default businessServices;
