import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";
import { HashLink as Link } from "react-router-hash-link";
import {
  Box,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material";
import PritLogo from "../../../assets/prit-logo.png";
import BlueButton from "../blueButton";

const useStyles = makeStyles((theme) => ({
  headerBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.10)",
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
  },
  headerLinks: {
    color: "#39ADC4",
    fontSize: "20px",
    cursor: "pointer",
    textDecoration: "none",
    whiteSpace: "nowrap",
    marginRight: '20px',
    "&:hover": {
      opacity: 0.7,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  headerLinksList: {
    color: "#39ADC4",
    fontSize: "20px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.7,
    },
  },
  pritLogoImg: {
    cursor: "pointer",
    maxWidth: "100%",
    height: "auto",
    width: "auto",
    "&:hover": {
      opacity: 0.7,
    },
  },
  headerButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  menuButton: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    marginLeft: theme.spacing(1),
  },
  list: {
    width: 200,
  },
  fullList: {
    width: "auto",
  },
}));

function PageHeader({
  featuresSectionRef,
  casesSectionRef,
  pricingSectionRef,
  is
}) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollToSection = (sectionRef) => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMobileOpen(open);
  };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Link
          to="/#features"
          smooth
          key={"Funcionalidades"}
          style={{ justifyContent: "flex-end" }}
          onClick={() => scrollToSection(featuresSectionRef)}
        >
          <ListItem>
            <Typography className={classes.headerLinksList}>
              {"Funcionalidades"}
            </Typography>
          </ListItem>
        </Link>
        <Link
          to="/#cases"
          smooth
          key={"Depoimentos"}
          style={{ justifyContent: "flex-end" }}
          onClick={() => scrollToSection(casesSectionRef)}
        >
          <ListItem>
            <Typography className={classes.headerLinksList}>
              {"Depoimentos"}
            </Typography>
          </ListItem>
        </Link>
        <Link
          to="/#pricing"
          smooth
          key={"Preço"}
          style={{ justifyContent: "flex-end" }}
          onClick={() => scrollToSection(pricingSectionRef)}
        >
          <ListItem>
            <Typography className={classes.headerLinksList}>
              {"Preço"}
            </Typography>
          </ListItem>
        </Link>
        <Link
          key={"Perguntas Frequentes"}
          style={{ justifyContent: "flex-end" }}
          onClick={() => {
            window.open("https://prit2207.zendesk.com/hc/pt-br");
            ReactPixel.track(`site - acessou perguntas frequentes`);
            mixpanel.track(`site - acessou perguntas frequentes`);
          }}
        >
          <ListItem>
            <Typography className={classes.headerLinksList}>
              {"FAQ"}
            </Typography>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <Box className={classes.headerBox}>
      <img src={PritLogo} alt="prit logo" className={classes.pritLogoImg} onClick={() => {
        window.open("/", '_self');
      }} />
      {!is && (
      <Box className={classes.headerButtons}>
        <Link
          to="/#features"
          smooth
          className={classes.headerLinks}
          onClick={() => scrollToSection(featuresSectionRef)}
          data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay="300"
        >
          Funcionalidades
        </Link>
        <Link
          className={classes.headerLinks}
          onClick={() => scrollToSection(casesSectionRef)}
          to="/#cases"
          smooth
          data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay="400"
        >
          Depoimentos
        </Link>
        <Link
          to="/#pricing"
          smooth
          className={classes.headerLinks}
          onClick={() => scrollToSection(pricingSectionRef)}
          data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay="500"
        >
          Preço
        </Link>
        <Link
          className={classes.headerLinks}
          onClick={() => {
            window.open("https://pritbusiness.zendesk.com/hc/pt-br");
            ReactPixel.track(`site - acessou perguntas frequentes`);
            mixpanel.track(`site - acessou perguntas frequentes`);
          }}
          data-aos={isMobile ? "" : "fade-in"}
          data-aos-delay="500"
        >
          Perguntas Frequentes
        </Link>
        <BlueButton
          text="Teste agora"
          delay={"600"}
        />
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon color="#4E5258" />
        </IconButton>
        <Drawer anchor="right" open={mobileOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </Box> )}
    </Box>
  );
}

export default PageHeader;
