import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import "swiper/css/bundle";
import LeftArrow from "../../../assets/icons/left-arrow.png";
import RightArrow from "../../../assets/icons/right-arrow.png";
import PlayBlue from "../../../assets/icons/icon-play-small-blue.png";
import BeatrizMattes from "../../../assets/beatriz-mattes.jpg";
import DayaneFelizardo from "../../../assets/dayane-felizardo.jpg";
import JoaoGabriel from "../../../assets/joao-gabriel.jpg";
import LeandraRosa from "../../../assets/leandra-rosa.jpg";
import MarianaBrufatto from "../../../assets/mariana-brufatto.jpg";
import PauloLeandro from "../../../assets/paulo-leandro-reis.jpg";
import PriscillaMoschiar from "../../../assets/priscilla-moschiar.jpg";
import TaynaraMoura from "../../../assets/taynara-moura.jpg";
import WalkanaerLucas from "../../../assets/walkanaer-lucas.jpg";

const useStyles = makeStyles((theme) => ({
  feedbackBox: {
    width: "100%",

    borderRadius: "40px 0px 0px 40px",
    background: "linear-gradient(90deg, #39ADC4 0%, #9B59CA 100%)",
    float: "right",
    padding: theme.spacing(10),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      borderRadius: "0",
      padding: theme.spacing(4),
      gap: 10,
    },
  },
  feedbackBoxHeader: {
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontSize: 40,
    lineHeight: "60px",
    color: "#fff",
    fontWeight: 500,
    fontFamily: "Nunito",
    textAlign: "center",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "normal",
    },
  },
  feedbackBoxArrows: {
    width: "fit-content",
    justifyContent: "flex-end",
    gap: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {},
  },
  opinionBoxWrapper: {
    width: "100%",
  },
  opinionBoxButton: {
    height: 48,
    boxShadow: " 0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
    textTransform: "none",
    color: "#7B7F87",
    fontWeight: 700,
    fontFamily: "Nunito",
    textAlign: "center",
    borderRadius: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
  },
  opinionBoxContainer: {
    display: "flex",
    flexDirection: "row",
    transition: "transform 2s ease-out",
    gap: theme.spacing(5),
  },
  opinionBox: {
    borderRadius: 30,
    background: "#FFF",
    boxShadow: "0px 0px 25px 10px rgba(0, 0, 0, 0.15)",
    padding: theme.spacing(2, 3.5),
    gap: 10,
    height: "100%",
    width: "110%",
    maxWidth: "500px",
    minWidth: "296px",
    cursor: "grab",
    [theme.breakpoints.down("md")]: {
      maxWidth: 450,
      marginBottom: theme.spacing(2),
      boxShadow: "none",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      width: "20%",
      marginBottom: theme.spacing(2),
      boxShadow: "none",
    },
  },
  opinionBoxHeader: {
    overflow: "visible",
    maxHeight: "65px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      maxHeight: "none",
      height: "auto",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
    },
  },
  avatar: {
    width: 100,
    height: 100,
    bottom: 60,
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 80,
      bottom: 0,
      top: -45,
      position: "absolute",
    },
  },
  testimonial: {
    color: "#7B7F87;",
    fontWeight: 400,
    fontFamily: "Nunito",
    fontSize: "18px",
    fontStyle: "italic",
  },
  clientName: {
    color: "#7B7F87;",
    fontWeight: 700,
    fontFamily: "Nunito",
    fontSize: "18px",
  },
  clientProf: {
    color: "#7B7F87;",
    fontWeight: 500,
    fontFamily: "Nunito",
    fontSize: "18px",
    textAlign: 'center',
  },
  clientInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  centralized: {
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  },
  swiperSlides:{
    padding: "0px 90px 30px 90px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 0px 30px 0px",
    },
  }
}));

const testimonials = [
  {
    avatar: PauloLeandro,
    testimonial:
      "“Mudou a nossa vida aqui na Levemente! Antes eu ficava quebrando a cabeça agendando a galera no papelzinho, cheguei a agendar 3 pessoas ao mesmo tempo. Hoje o pessoal agenda pelo app, não tem mais como agendar 2 horários ao mesmo tempo, é maravilhoso e eu super recomendo!”",
    name: "Paulo Leandro Reis",
    profession: "Massoterapeuta",
    url: "https://youtu.be/_9s9-mOHybs",
  },
  {
    avatar: TaynaraMoura,
    testimonial:
      "“Antes eu trabalhava com regras de atendimento, mas não estava funcionando muito bem, eu estava tendo um prejuízo de mais de R$ 3000,00 em multas e cancelamentos que as clientes não compareciam e não tinha como eu cobrar essa multa, e hoje com o Prit eu não tenho mais esse problema”",
    name: "Taynara Moura",
    profession: "Depiladora",
    url: "https://www.youtube.com/shorts/yvujvhMojOk",
  },
  {
    avatar: BeatrizMattes,
    testimonial:
      "“Eu sempre fiz atendimento sozinha, então eu tinha muita dificuldade com agendamento de consulta de clientes e com muitas mensagens eu acabava perdendo muitos clientes. Depois que eu comecei a usar o Prit isso mudou, atualmente não perco mais nenhum cliente”",
    name: "Beatriz Mattes",
    profession: "Médica Veterinária",
    url: "https://youtu.be/7LGy8mlzREg",
  },
  {
    avatar: MarianaBrufatto,
    testimonial:
      "“Ele mudou a minha vida! Hoje em dia eu tenho tempo de sair, tempo de ir na academia, tempo de ver minha família. Antes eu ficava muito tempo fazendo os agendamentos, e hoje em dia vejo o quanto de tempo livre eu tenho para fazer outras coisas da minha vida pessoal e profissional”",
    name: "Mariana Brufatto",
    profession: "Nail Designer",
    url: "https://youtu.be/vpkXf65XbYg",
  },
  {
    avatar: WalkanaerLucas,
    testimonial:
      "“Aplicativo excelente onde eu consigo agendar meus alunos e consigo determinar o limite de vagas para cada horário. É uma ferramenta muito bacana que está me ajudando demais!”",
    name: "Walckenaer Lucas",
    profession: "Educador Físico",
    url: "https://youtu.be/Ede4olIqRtg",
  },
  {
    avatar: LeandraRosa,
    testimonial:
      "“O aplicativo Prit veio para otimizar muito meu tempo e facilitar o meu dia a dia. Antigamente eu tinha que andar com uma agenda de papel, agora eles conseguem entrar no aplicativo e agendam instantaneamente o serviço. Eu gosto muito do aplicativo e tudo o que eu preciso sempre tem uma pessoa disponível para me ajudar!”",
    name: "Leandra Rosa",
    profession: "Fisioterapeuta",
    url: "https://www.youtube.com/shorts/hThOhZoKcII",
  },
  {
    avatar: JoaoGabriel,
    testimonial:
      "“Ganhei muito tempo utilizando o aplicativo! Antes eu não tinha tanto tempo para atender os clientes porque eu ficava muito tempo no WhatsApp, e com o app eu consigo dar mais atenção para os clientes.”",
    name: "João Gabriel",
    profession: "Barbeiro",
    url: "https://www.youtube.com/shorts/Ikn4VsojQ4Y",
  },
  {
    avatar: PriscillaMoschiar,
    testimonial:
      "“Minha vida é uma loucura, eu me desdobro para cumprir todas as tarefas, e não posso falhar com o meu negócio, preciso estar sempre atenta. Com isso, eu adquiri o Prit e me ajudou muito a conseguir dar conta de tudo, porque fica ali o link para cliente acessar e agendar e me facilita demais!”",
    name: "Priscilla Moschiar",
    profession: "Maquiadora e Designer de Sobrancelhas",
    url: "https://youtu.be/59mf8V2sxT4",
  },
  {
    avatar: DayaneFelizardo,
    testimonial:
      "“Eu marcava com a cliente e esquecia de pôr na agenda, e quando chegava aqui tinha duas, três, me esperando, e era sempre aquele rolo e a vergonha de mandar a cliente embora. Ai conheci o Prit e fui super prático! Eu super recomendo.”",
    name: "Dayane Felizardo",
    profession: "Extensionista de Cílios",
    url: "https://youtu.be/kQSOuJgjZqw",
  },
];

function FeedBackSection() {
  const classes = useStyles();
  const theme = useTheme();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [leftArrowOpacity, setLeftArrowOpacity] = useState(1);
  const [rightArrowOpacity, setRightArrowOpacity] = useState(1);
  const sliderRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  function OpinionBox({ avatar, testimonial, name, profession, url }) {
    return (
      <Grid className={classes.opinionBox} container>
        <Grid className={classes.opinionBoxHeader} container>
          <Avatar className={classes.avatar} src={avatar}></Avatar>
          <Button
            className={classes.opinionBoxButton}
            onClick={() => {
              window.open(url);
            }}
          >
            <IconButton>
              {" "}
              <img src={PlayBlue} alt="play-button" />
            </IconButton>{" "}
            <Typography>Ver depoimento</Typography>
          </Button>
        </Grid>
        <Typography className={classes.testimonial}>{testimonial}</Typography>
        <Grid className={classes.clientInfo}>
          <Typography className={classes.clientName}>{name}</Typography>
          <Typography className={classes.clientProf}>{profession}</Typography>
        </Grid>
      </Grid>
    );
  }

  const handleNext = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
        const swiper = sliderRef.current.swiper;
        if (currentSlide < swiper.slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    }
};

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };
  
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
  };

  const updateArrowsOpacity = () => {
    if (sliderRef.current && sliderRef.current.swiper) {
      const swiper = sliderRef.current.swiper;
      setLeftArrowOpacity(swiper.isBeginning ? 0.5 : 1);
      setRightArrowOpacity(swiper.isEnd ? 0.5 : 1);
    }
  };

  useEffect(() => {
    sliderRef.current.swiper.slideTo(currentSlide, 500, false);
    updateArrowsOpacity();
  }, [currentSlide]);

  return (
    <Grid className={classes.feedbackBox} container id="cases" data-aos={isMobile ? "" : "fade-in"} data-aos-duration="1200">
      <Grid className={classes.feedbackBoxHeader} container>
        <Typography variant="h2" className={classes.headerTitle}>
          O que nossos clientes dizem sobre nós?
        </Typography>
        {!isMobile && (
          <Grid className={classes.feedbackBoxArrows} container>
            <IconButton onClick={handlePrevious}>
              <img src={LeftArrow} alt="previous-arrow" style={{ opacity: leftArrowOpacity }}/>
            </IconButton>
            <IconButton onClick={handleNext}>
              <img src={RightArrow} alt="next-arrow" style={{ opacity: rightArrowOpacity }}/>
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Swiper
        ref={sliderRef}
        style={{ padding: "55px 0 0 0" }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          370: {
            slidesPerView: 1.05,
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 1.4,
            spaceBetween: 20,
          },
          550: {
            slidesPerView: 1.3,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 1.8,
            spaceBetween: 10,
          },
          1000: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          1300: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1500: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          1900: {
            slidesPerView: 3.1,
            spaceBetween: 20,
          },
        }}
        onSlideChange={(swiper) => {
          handleSlideChange(swiper)
        }}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} className={classes.swiperSlides}>
            <OpinionBox
              url={testimonial.url}
              avatar={testimonial.avatar}
              testimonial={testimonial.testimonial}
              name={testimonial.name}
              profession={testimonial.profession}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {isMobile && (
        <Grid
          className={classes.feedbackBoxArrows + " " + classes.centralized}
          container
        >
          <IconButton onClick={handlePrevious}>
            <img src={LeftArrow} alt="previous-arrow" style={{ opacity: leftArrowOpacity }}/>
          </IconButton>
          <IconButton onClick={handleNext}>
            <img src={RightArrow} alt="next-arrow" style={{ opacity: rightArrowOpacity }}/>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default FeedBackSection;
