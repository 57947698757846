import { useLocation } from 'react-router-dom';
import { isEmpty, trim } from 'lodash';

export const URLGenerator = () => {
    const location = useLocation();
    const utmContent = new URLSearchParams(location.search).get("utm_content");

    const verifiedUtmContent = utmContent ? trim(utmContent) : "";
    const defaultDeepLinkValue = "ab72b843be1948b0c72b2fbaa99d77b7";

    return `https://prit-business.onelink.me/Mxb0?pid=User_invite&deep_link_value=${verifiedUtmContent && !isEmpty(verifiedUtmContent) ? verifiedUtmContent : defaultDeepLinkValue}`;
};